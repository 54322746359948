import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Link from "next/link";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import {
  Field as BulmaField,
  Control,
  Label,
  Input,
  Help
} from "react-bulma-components/lib/components/form";
import {
  Button,
  Heading,
  Content,
  Loader,
  Notification
} from "react-bulma-components";
import {
  colors,
  DEFAULT_SIGNIN_REDIRECT,
  GOOGLE_OAUTH_CLIENT_ID,
  COLORS,
  UNDEFINED
} from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebase from "firebase";
import "firebase/auth";
import { useAuth } from "../../lib/use-auth";
import { useRouter } from "next/router";
import { noop } from "lodash/noop";

const SignIn = ({
  next = null,
  header = "Sign In",
  success,
  failure = noop
}) => {
  const { user, emailVerifySent } = useAuth();
  const [loading, setLoading] = useState(false);
  const [authResult, setAuthResult] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!emailVerifySent) {
      success(authResult, redirectUrl);
    }

    setLoading(true);
    const timeOut = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [user]);

  // Configure FirejibaseUI.
  const uiConfig = {
    siteName: "Bounty Hiring",
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      {
        clientId: GOOGLE_OAUTH_CLIENT_ID,
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
      },
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    tosUrl: "/policies/terms-of-service",
    privacyPolicyUrl: "/policies/privacy",
    queryParameterForSignInSuccessUrl: "next",
    callbacks: {
      signInSuccessWithAuthResult: (authRes, redirectURL) => {
        setAuthResult(authRes);
        setRedirectUrl(redirectURL);
        if (next) {
          return true;
        } else {
          return false;
        }
      },
      signInFailure: async err => {
        setError(err);
        return failure(err);
      }
    },
    signInSuccessUrl: next
  };

  return (
    <div>
      <Content>
        <Heading>{header}</Heading>
        {error && (
          <Notification color={COLORS.DANGER}>{error.toString()}</Notification>
        )}
        {emailVerifySent ? (
          <Notification color={COLORS.LIGHT}>
            "Please verify your email before proceeding further."
          </Notification>
        ) : (
          user && (
            <Notification color={COLORS.SUCCESS}>
              You're signed in! {loading && <Loader />}
            </Notification>
          )
        )}
      </Content>
      {typeof window !== UNDEFINED ? (
        <StyledFirebaseAuth
          uiConfig={{
            ...uiConfig,
            signInSuccessUrl: next
          }}
          firebaseAuth={firebase.auth()}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

SignIn.propTypes = {
  next: PropTypes.string
};

export default SignIn;
